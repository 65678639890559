import React from "react"
import Page from "./../templates/page.js"
import { Helmet } from 'react-helmet'
import { Link } from "gatsby"
import { Icon } from "./../components/helpers.js"
import { TiHome } from "react-icons/ti";

export default () => {

    const main = 
        <><h2>Page not Found</h2><p><Icon><TiHome /></Icon> <Link to="/">Back to home</Link></p></>;

    return (
        <>
            <Helmet>
                <title>404 - Maakbaas</title>
                </Helmet>
            <Page title={'404'} content={main} 
                  />
        </>);

}